/**=====================
     38. Landing CSS Start
==========================**/
/* MESH LOADER */
@keyframes mesh {
  0% {
    transform-origin: 50% -100%;
    transform: rotate(0);
  }
  50% {
    transform-origin: 50% -100%;
    transform: rotate(360deg);
  }
  50.1% {
    transform-origin: 50% 200%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 50% 200%;
    transform: rotate(360deg);
  }
}
.mesh-loader {
  overflow: hidden;
  height: inherit;
  width: inherit;
  .circle {
  position: absolute;
  background: $primary-color;
  border-radius: 50%;
  margin: -3px;
  animation: mesh 3s ease-in-out infinite -1.5s;
      width: 6px;
    height: 6px;
  }
  > div .circle:last-child {
    animation-delay: 0s;
  } 
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  > div:last-child {
    transform: rotate(90deg);
  }
}

@-webkit-keyframes animloader14 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes animloader14 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.loader-1 {
    width: 76px;
    height: 76px;
    display: inline-block;
    position: relative;
    &::after , &::before {
      content: '';
      width: 76px;
      height: 76px;
      border-radius: 50%;
      background: #80a5ff;
      position: absolute;
      left:0;
      top: 0;
      animation: animloader14 2s linear infinite;
    }
    &::after {
      animation-delay: 1s;
    }
    @media (max-width: 1199px) {
      width: 60px;
      height: 60px;
      &::after , &::before {
        width: 60px;
        height: 60px;
      }
    }
    @media (max-width: 575px) {
      width: 40px;
      height: 40px;
      &::after , &::before {
        width: 40px;
        height: 40px;
      }
    }
  }
.landing-page {
  $body-font-color: #000248;
  color: $body-font-color;
  .accordion-item {
    border: none;
  }
  .accordion-button {
    &:not(.collapsed) {
      box-shadow: none;
    }
  }
  .customization-accordion {
  .accordion-collapse {
    border: none;
  }
  .accordion-button {
    font-weight: 500;
    position: relative;
    border: none;
    padding: 10px 10px 10px 30px;
    color: $body-font-color;
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin-left: 0;
      background-image: url(../images/landing/icon/plus.svg);
      background-position: center;
      background-size: 16px;
    }
    &:focus {
      box-shadow: none;
    }
    &:not(.collapsed) {
      background-color: transparent;
      &::after {
        background-image: url(../images/landing/icon/minus.svg);
      }
    }
  }
  .accordion-body {
    padding-top: 0;
    padding-bottom: 10px;
    text-align: left;
    padding-left: 30px;
  }
}
}
.animate-slider {
  .slick-slide {
    img {
      width: 100%;
    }
  }
}
.f-light {
  color: #52526C;
  opacity: 0.8;
}

.vector-image {
  position: absolute; 
   @media (max-width: 991px) {
      display: none;
    }
}
.line-gif {
  left: 20%;
  top: 0;
  z-index: 1;
  img {
    width: 140px;
    height: 140px;
    @media (max-width: 1399px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 991px) {
      width: 80px;
      height: 80px;
    }
    @media (max-width:575px) {
      width: 60px;
      height: 60px;
    }
  }
  @media (max-width: 1460px) {
    left: 14%;
  }
  @media (max-width: 1399px) {
    left: 21%;
  }
  @media (max-width:575px) {
    left: 7%;
    top: -30px;
  }
}
.wavy-line-gif {
    right: 0;
    top: 3%;
    @media (max-width: 991px) {
      top: 1%;
    }
    @media (max-width:767px) {
      top: 40px;
    }
    @media (max-width:575px) {
      top: 10px;
    }
    img {
      width: 200px;
      @media (max-width:1199px) {
        width: 150px;
      }
      @media (max-width:767px) {
        width: 120px;
      }
      @media (max-width:575px) {
        width: 90px;
      }
    }
}
.round-gif {
  left: 5%;
  top: 20%;
  @media (max-width: 1660px) {
    left: 2%;
  }
  @media (max-width: 1399px) {
    left: 0%;
    top: 15%;
  }
  @media (max-width: 1199px) {
    top: 20%;
  }
  @media (max-width: 991px) {
    top: 28%;
  }
  @media (max-width: 767px) {
    top: -2%;
  }
  img {
    width: 147px;
    height: 147px;
    @media (max-width: 1660px) {
      width: 127px;
      height: 127px;
    }
    @media (max-width: 1199px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 991px) {
      width: 70px;
      height: 70px;
    }
  }
}
 .wavy-gif {
    right: 17%;
    top: 23%;
    svg {
      width: 80px;
      transform: rotate(90deg);
      @media (max-width: 1199px) {
        width: 60px;
      }
      @media (max-width: 575px) {
        width: 30px;
      }
    }
    path {
      stroke: #f8912b;
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: dash 1.5s linear infinite;
    }
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
    @media (max-width:1399px) {
      right: 3%;
      top: 15%;
    }
    @media (max-width:991px) {
      right: 1%;
      top: 10%;
    }
    @media (max-width: 767px) {
      top: 8%;
    }
    img {
      transform: rotate(90deg);
      width: 120px;
      @media (max-width:1399px) {
        width: 80px;
      }
      @media (max-width: 767px) {
        width: 60px;
      }
      @media (max-width: 575px) {
         width: 40px;
      }
    }
  }
.app-section {
  .wavy-gif {
    right: unset;
    left: 20%;
    top: 7%;
    @media (max-width: 1660px) {
      left: 8%;
    }
    @media (max-width: 1199px) {
      left: 3%;
      top: 3%;
    }
     @media (max-width: 767px) {
       top: 30px;
     }
    svg {
      width: 50px;
      @media (max-width: 767px) {
        width: 40px;
      }
    }
  }
}
.loader-gif {
  right: 5%;
  top: 25%;
  @media (max-width: 1199px) {
    top: 32%;
  }
  @media (max-width: 991px) {
    right: 15px;
  }
  @media (max-width: 575px) {
    top: 28%;
  }
}
.loader-line-gif {
  right: 15%;
  top: 12%;
  @media (max-width: 1660px) {
    right: 5%;
  }
  @media (max-width: 1399px) {
    top: 10%;
    right: 0;
  }
  @media (max-width: 1199px) {
    top: 9%;
  }
  @media (max-width: 991px) {
    top: 0;
  }
  img {
    width: 130px;
    @media (max-width: 575px) {
      width: 80px;
    }
  }
}
.premium-wrap {
  margin-top: -28px;
  .vector-image  {
    top: -50px;
    left: -30px;
    @media (max-width: 1399px) {
      left: 75px;
    }
    @media (max-width: 1199px) {
      left: -15px;
    }
    img {
      height: 400px;
      @media (max-width: 1399px) {
        height: 220px;
      }
    }
  }
}
.framework-section {
  .wavy-gif {
    top: unset;
    bottom: 0;
    right: 20%;
    @media (max-width: 1399px) {
      bottom: -30px;
      right: 0;
    }
    @media (max-width: 575px) {
      bottom: 0;
    }
  }
}
.components-section {
  .flower-gif {
    left: unset;
    right: 5%;
    top: 20%;
    @media (max-width:991px) {
      right: 10%;
      top: 9%;
    }
  }
}
.feature-section {
  .vector-image  {
      top: 50px;
      right: -170px;
      @media (max-width: 1600px) {
          top: 0px;
          right: -20px;
      }
      @media (max-width: 1399px) {
          top: 10px;
          right: -10px;
      }
      @media (max-width: 1199px) {
          right: -12px;
      }
      img {
        height: 370px;
        @media (max-width: 1600px) {
          height: 300px;
        }
        @media (max-width: 1399px) {
          height: 280px;
        }
      }
    } 
  .round-gif{
    top: unset;
    bottom: 12%;
    @media (max-width: 1660px) {
      left: 0;
    }
    @media (max-width: 1460px) {
      bottom: unset;
      top: 6%;
    }
    @media (max-width: 991px) {
      top: -3%;
    }
    @media (max-width: 575px) {
      top: -2%;
    }
    img {
      width: 105px;
      height: 105px;
      @media (max-width: 1460px) {
        width: 90px;
        height: 90px
      }
      @media (max-width: 575px) {
        width: 70px;
        height: 70px
      }
    }
  }  
}
.document-section {
  a {
    display: block;
    color: $body-font-color;
  }
  .document-box {
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
    border-radius: 5px;
    padding: 20px;
    @media (max-width: 767px) {
      gap: 14px;
    }
    @media (max-width: 575px) {
      gap: 10px;
      padding: 15px;
    }
    .doc-icon {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      img {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    }
    h5 {
      margin-bottom: 0;
    }
  }
  .row {
    > div {
      @media (max-width: 479px) {
        width: 100%;
      }
    }
  }
  .bg-icon1 {
     background: #F0E9FB;
  }
  .bg-icon2 {
    background: rgba(222, 77, 89, 0.1);
  }
  .bg-icon3 {
    background: rgba(77,186,135,0.15);
  }
  .bg-icon4 {
    background: rgba(103, 190, 253,0.12);
  }
  .bg-icon5 {
    background: rgba(255, 227, 225,0.59);
  }
  .bg-icon6 {
    background: rgba(65, 184, 131, 0.15);
  }
  .bg-icon7 {
    background: rgba(158,149, 255, 0.22);
  }
  .bg-icon8 {
    background: rgba(238, 67, 35, 0.12);
  }
  .bg-icon9 {
    background: rgba(154, 214, 183, 0.22);
  }
  .bg-icon10 {
    background: rgba(255, 153, 33, 0.14);
  }
}
.rating-title {
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1920 - 300)));
  font-weight: normal;
}
.layout {
  h5 {
    margin-bottom: 10px;
    opacity: .8;
    letter-spacing: 1.2px;
  }

  .btn {
    padding: 0.15rem 0.5rem;
  }
}
.common-card {
   background: #FCFCFD;
  border: 2px solid $white;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  border-radius: 10px;
}
.testimonial-box {
  position: relative;
  padding: 20px 25px;
  text-align: left;
  margin-bottom: 33px;
  .rating-content {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  h5 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .text-end {
    span {
      font-size: 13px;
    }
  }
  .customer-img {
    width: 60px;
    height: 60px;
    background: #FFFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -25px;
  }
  p {
    margin-bottom: 0;
  }
}
.about-section {
  .slick-slide {
    padding: 0 14px;
  }
}

.testimonial-slider {
  .slick-dots {
    li {
      width: auto;
      height: auto;
      button {
        background: rgba(82, 82, 108, 0.2);
        border-radius: 20px;
        height: 5px;
        width: 11px;
        padding: 0;
        transition: all 0.5s;
        &::before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: $primary-color;
          border-radius: 20px;
          width: 20px;
          transition: all 0.5s;
        }
      }
    }
  }
}
.customer-box {
  position: relative;
  background-color: #f7f7f7;
  text-align: center;
  padding: 40px 30px;
  border-radius: 25px;
  box-shadow: 30px 30px 33px rgba(119 ,119, 119, 0.25);
  @media (max-width: 1399px) {
    padding: 40px 35px;
  }
  @media (max-width: 1199px) {
    padding: 30px 25px;
  }
  @media (max-width: 991px) {
    padding: 17px 13px;
  }
  @media (max-width: 575px) {
    box-shadow: 12px 12px 12px rgba(119 ,119, 119, 0.25);
  }
  img {
    height: 70px;
    width: auto;
    @media (max-width: 991px) {
       height: 50px;
    }
  }
}
.customer-wrapper {
  position: relative;
  padding: 12px;
   @media (max-width: 767px) {
    padding: 0;
   }
  .outline-box {
    position: absolute;
    bottom: -6px;
    left: -5px;
    @media (max-width: 1399px) {
      bottom: -1px;
      left: 0px;
    }
    @media (max-width: 1199px) {
      bottom: -1px;
      left: -2px;
      width: 120%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.customer-wrap {
  margin: 0 -28px;
  @media (max-width: 1199px) {
    margin: 0 -15px;
  }
  @media (max-width: 991px) {
    margin: 0 -10px;
  }
  @media (max-width: 767px) {
    margin: -12px;
  }
  > div {
    padding: 0 28px;
    @media (max-width: 1199px) {
      padding: 0 15px;
    }
    @media (max-width: 991px) {
      padding: 0 10px;
    }
    @media (max-width: 767px) {
      padding: 12px;
    }
  }
  > div {
    &:last-child {
      @media (max-width: 1199px) {
        .outline-box {
          width: 100%;
        }
      }
    }
  }
}
.frame-tab {
  background: rgba(244, 245, 248, 0.5);
  border-radius: 40px;
  border: none;
  gap: 10px;
  display: inline-flex;
  padding: 5px;
  margin-bottom: 50px;
  .nav-item {
    img {
      width: 21px;
      height: 21px;
    }
    .nav-link {
      font-size: 18px;
      padding: 16px 40px;
      border-radius: 40px;
      background: transparent;
      border: none;
      color: rgba(0, 2, 72, 0.5);
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 16px;
        padding: 10px 25px;
      }
      img {
        margin-right: 10px;
        filter: grayscale(1);
      }
      &.active {
        color: #000248;
        background: $white;
        box-shadow: 0px 7px 15px rgba(46, 35, 94, 0.06);
        img {
          filter: unset;
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    &.nav {
      display: inline-flex;
      background-color: transparent;
    }
  }
}
.framework-box {
  position: relative;
  display: flex;
  align-items: center;
  background: #FCFCFD;
  border: 2px solid $white;
  box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
  border-radius: 50px 16px 16px 50px;
  padding: 18px 16px;
  gap: 16px;
  margin-left: 30px;
  transition: all 0.5s;
  &::after {
    position: absolute;
    content: '';
    width: 40px;
    height: calc(100% + 4px);
    border-right: 1px dashed rgba(0, 2, 72, 0.2);
    right: -1px;
    border-top: 1px dashed rgba(0, 2, 72, 0.2);
    border-bottom: 1px dashed rgba(0, 2, 72, 0.2);
    border-radius: 10px;
    top: -1px;
    bottom: 0;
  }
  .frame-icon {
    position: relative;
    min-width: 55px;
    height: 55px;
    background: $white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -48px;
    @media (max-width: 575px) {
      min-width: 52px;
      height: 52px;
    }
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      box-shadow: 3px 4px 5px rgba(46, 35, 94, 0.11), inset 3px 0px 1px rgba(204, 204, 215, 0.5);
       border-radius: 100%;
    }
    img {
      width: 30px;
      height: 30px;
      @media (max-width: 575px) {
        width: 25px;
        height: 25px;
      }
    }
  }
  .frame-details {
    position: relative;
    text-align: left;
    p {
      opacity: 0.7;
      margin-bottom: 0;
    }
    &::before, &::after {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background: #FEFEFF;
      border: 1px solid rgba(0, 2, 72, 0.2);
      border-radius: 100%;
      right: 17px;
    }
    &::before {
      top: -23px;
    }
    &::after {
      bottom: -24px;
    }
  }
  &:hover {
    @media (min-width: 576px) {
      transform: scale(1.05);
      transition: all 0.5s;
    }
    .frame-icon {
      &::before {
        animation-name: rotate;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
}
@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}
.customization-wrap {
  .landing-title {
     p {
      width: 100%;
     }
  }
}

.sub-title {
  font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 700;
  font-family: $landing-cursive;
  color: #FFB905;
}
.rotate-title {
  color: #FF5A82;
}
.landing-home {
  scroll-behavior: smooth;
  overflow: hidden;
  position: relative;
  background-position: bottom;
  background-size: cover;
  background-image: url(../images/landing/home-bg.jpg);
  background-color: #1b1c21;
  .sub-title {
    font-size: calc(18px + (30 - 18) * ((100vw - 300px) / (1920 - 300)));
  }
  .content {
    h1 {
      color: $white;
      line-height: 1.2;
      font-size: calc(25px + (60 - 25) * ((100vw - 300px) / (1920 - 300)));
      text-transform: uppercase;
      span {
        background: linear-gradient(90deg, #FFB905 20.35%, #6AEBAD 50%),
                    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
      font-weight: 500;
      font-family: roboto;
      color: $white;
      opacity: 0.8;
      width: 64%;
      margin: 0 auto;
      @media (max-width: 1366px) {
        width: 90%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .btn-grp {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      gap: 12px;
    }
    @media (max-width: 575px) {
      flex-wrap: wrap;
      gap: 10px;
    }
    img {
      height: 30px;
      @media (max-width: 767px) {
        height: 22px;
      }
      @media (max-width: 575px) {
        height: 20px;
      }
    }
  }

  .content {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    @media (max-width: 1366px) {
      margin-top: 170px;
    }
    @media (max-width: 1199px) {
      margin-top: 140px;
    }
    @media (max-width: 575px) {
      margin-top: 100px;
    }
  }

  .content-title {
    position: relative;
    display: inline-block;
    .arrow-decore {
      position: absolute;
      left: -203px;
      top: -25px;
      @media (max-width: 1660px) {
        left: -166px;
        width: 150px;
      }
       @media (max-width: 991px) {
          left: -125px;
          width: 110px;
          top: -14px;
       }
       @media (max-width: 575px) {
         left: -61px;
          width: 60px;
          top: -4px;
       }
    }
  }

  .sticky-header {
    header {
      .nav-padding {
        padding: 30px 50px;
      }
    }
  }
}
.build-section {
  position: relative;
  .landing-title {
    display: flex;
    align-items: center;
    h2 {
      width: 62%;
      @media (max-width: 1660px) {
        width: 58%;
      }
      @media (max-width: 1366px) {
        width: 59%;
      }
       @media (max-width: 1199px) {
        width: 63%;
      }
      @media (max-width: 991px) {
         width: 100%;
      }
    }
    .title-img {
      margin-bottom: -14px;
      @media (max-width: 991px) {
          width: 120px;
      }
      @media (max-width: 767px) {
        width: 80px;
      }
    }
  }
  .rotate-title {
    width: 14%;
    transform: rotate(13.97deg);
    text-align: center;
    margin-left: -20px;
    @media (max-width: 1199px) {
        width: 17%;
    }
    @media (max-width: 991px) {
        width: 30%;
    }
    @media (max-width: 767px) {
        width: 42%;
    }
  }
  .build-image {
    img {
      width: calc(100% + 210px);
      @media (max-width: 991px) {
        width: calc(100% + 100px);
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

@keyframes animationtop {
  0% {
    transform: translate(-10px, -5px);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-10px, -5px);
  }
}

@keyframes animationleft {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(20px, 0);
  }

  100% {
    transform: translate(0, 0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


$landing-color : var(--theme-deafult);

.navbar {
  justify-content: flex-start;
  .navbar-toggler {
    margin-left: auto;
  }
}

.navbar-nav {
  align-items: center;

  .nav-item {
    position: relative;

    .nav-link {
      font-size: 18px;
      letter-spacing: 1px;
      padding: 12px 20px;
      transition: all 0.3s ease;

      &:hover,
      &.active {
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  }
}

.landing-page {
  .navbar-expand-xl {
    .navbar-collapse {
      background-color: transparent;
    }
  }

  .page-body-wrapper {
    min-height: 100vh;
    background: #eaf4ff;
    overflow: hidden;
  }

  .buy-btn {
    font-size: 16px;
    background: linear-gradient(90deg, #7366FF -18.33%, #563EE0 62.78%);
    border-radius: 5px;
    padding: 15px 54px;
    margin-left: 10px;
    @media (max-width: 991px) {
      padding: 12px 35px;
    }
    @media (max-width: 767px) {
      padding: 9px 20px;
      font-size: 14px;
    }
    a {
      color: $white;
      padding: 0;
    }
  }

  .navbar-nav {
    
    .nav-item {

      .nav-link {
        letter-spacing: 1.5px;
        color: $white;
      }
    }
  }

  .sticky-header {
    header {
      background: transparent;
      box-shadow: none;
      z-index: 100;
      left: 0;
      height: auto;

      &.sticky {
        margin-top: 0;
        background: #1d1f29;

        .navbar-nav .nav-item .nav-link {
          font-size: 16px;
          padding: 15px 12px;
        }

      }
    }
  }
}

.navbar-expand-xl {
  .navbar-brand {
    padding-left: 18px;
  }

  .navbar-collapse {
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: $white;
  }
}

.section-space {
  position: relative;
  padding: 80px 0;
  text-align: center;

  p {
    font-size: 15px;
    line-height: 1.5;
  }
}

.landing-title {
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  h2 {
    font-size: calc(25px + (52 - 25) * ((100vw - 300px) / (1920 - 300)));
  }
  .gradient-1 {
    background: linear-gradient(90.78deg, $primary-color 49.33%, $secondary-color 82.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-2 {
    background: linear-gradient(90deg, #FF6A5B 4.44%, #1E52B9 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-3 {
    background: linear-gradient(90deg, #7B5DFE 11.24%, #FFAB2E 50%),
    linear-gradient(0deg, #FF6A5B, #FF6A5B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-4 {
    background: linear-gradient(90deg, #22AC16 65.06%, #1C97BE 92.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-5 {
    background: linear-gradient(90deg, #6A2FE7 2%, #46B7D0 40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-6 {
    background: linear-gradient(271deg, #F1305F 30%, #0066FF 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-7 {
    background: linear-gradient(90deg, #FF7A00 20%, #D72695 89.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-8 {
    background: linear-gradient(257.2deg, #FF5E51 21.09%, #FFAB6F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-9 {
    background: linear-gradient(90deg, rgba(255, 57, 57, 0.88) 2%, #24D34B 103.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-10 {
    background: linear-gradient(90deg, #4A86FB 50%, #FF5823 68.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-11 {
    background: linear-gradient(90deg, #FF1717 22.23%, #0A96E6 68.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-12 {
    background: linear-gradient(90deg, #89D32B 5.6%, #FF7A00 36.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-13 {
    background: linear-gradient(90deg, #FF6056 31.63%, #7F74FE 67.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } 
  p {
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
    width: 65%;
    margin: 0 auto;
    color: #52526C;
    opacity: 0.8;
    @media (max-width: 1199px) {
      width: 80%;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}


.bottom-section-grad {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #EDF7FF -3.32%, rgba(227, 242, 255, 0) 144.14%);
    opacity: 0.7;
    height: 100%;
    width: 100%;
  }
}

.faq-section {
  .accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .accordion-item {
      background: #FCFCFD;
      border: 2px solid $white;
      box-shadow: 0px 4px 10px rgba(46, 35, 94, 0.07);
      border-radius: 10px;
      padding: 22px 30px;
      @media (max-width: 767px) {
        padding: 15px 20px;
      }
    }
    &-button, &-body {
      padding: 0;
    }
    &-button {
      &:not(.collapsed) {
        color: $primary-color;
        background: transparent;
      }
      &:focus {
        box-shadow: unset;
        border: none;
      }
      &::after {
        font-family: 'IcoFont';
        content: "\eb25";
        font-size: 24px;
        background: unset;
        width: auto;
        height: auto;
        margin-bottom: -4px;
      }
    }
    &-body {
      padding-top: 14px;
      margin-top: 14px;
      border-top: 1px solid rgba(82, 82, 108, 0.5);
      text-align: left;
    }
  }
  .vector-image {
    left: -160px;
    top: 30px;
    @media (max-width: 1600px) {
      left: 60px;
      top: -30px;
    }
     @media (max-width: 1199px) {
      left: 0px;
      top: -30px;
    }
    img {
      height: 400px;
      @media (max-width: 1600px) {
        height: 270px;
      }
    }
  }
}

.pricing-section {
  .landing-title {
     p {
      width: 100%;
     }
  }
  .loader-gif {
    top: -5%;
    @media (max-width:1600px) {
      top: -11%;
      right: 2%;
    }
     @media (max-width:991px) {
      top: -2%;
      right: 2%;
     }
     .loader-1 {
      width: 56px;
      height: 56px;
      &::after, &::before {
        width: 56px;
        height: 56px;
      }
      @media (max-width:575px) {
        width: 40px;
        height: 40px;
        &::after, &::before {
          width: 40px;
          height: 40px;
        }
      }
     }
  }
  .loader-line-gif {
    right: unset;
    top: unset;
    left: 22%;
    bottom: 15%;
    @media (max-width:1600px) {
      left: 12%;
    }
    @media (max-width:1600px) {
      left: 16%;
    }
    @media (max-width:991px) {
      left: 10px;
      bottom: -20px;
      img {
        width: 90px;
      }
    }
  }
}
.pricing-box  {
  background: rgba(243, 245, 245, 0.5);
  border: 2px solid $white;
  border-radius: 10px;
  padding: 30px 20px;
  img {
    width: 23px;
    height: 22px;
  }
  @media (max-width: 767px) {
    padding: 20px 15px;
  }
  p {
    color: #52526C;
  }
  h4 {
    margin-bottom: 20px;
    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
  }
  p {
    line-height: 1.5;
  }
  .purchase-details {
    background: #FCFCFD;
    border: 2px solid $white;
    box-shadow: 0px 4px 20px rgba(46, 35, 94, 0.05);
    border-radius: 10px;
    padding: 24px 40px;
    margin-top: 20px;
    @media (max-width: 1199px) {
      padding: 24px 20px;
    }
    h2 {
      margin-bottom: 14px;
    }
    .btn-lg {
      font-weight: 500;
      padding: 12px 20px;
      width: 100%;
      min-width: auto;
      @media (max-width: 1199px) {
        padding: 12px 10px;
        font-size: 15px;
      }
    }
  }
}
.license-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 767px) {
        gap: 10px;
    }
    li {
      display: flex;
      align-items: center;
      text-align: left;
      gap: 10px;
      @media (max-width: 991px) {
        gap: 5px;
      }
      @media (max-width: 575px) {
        img {
          width: 18px;
          height: 18px;
        }
      }
      h6 {
        font-weight: normal;
        margin-bottom: 0;
      }
    }
}

.feature-box {
  padding: 30px 20px;
  text-align: left;
  position: relative;
  @media (max-width: 575px) {
    padding: 20px;
  }
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 5px;
    bottom: 0;
    left: 0;
    background: #F94646;
    border-radius: 0 0 10px 10px;
    transition: width 0.5s;
  }
  &:hover {
    &::after {
      width: 100%;
      transition: width 0.5s;
    }
  }
  .feature-icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 25px;
  }
  &.bg-feature-1 {
    .feature-icon { 
       background: #FBECEC;
    }
  }
  &.bg-feature-2 {
    .feature-icon {
      background: #F0E9FB;
    }
    &::after {
      background: #6610F2;
    }
  }
  &.bg-feature-3 {
    .feature-icon {
      background: rgba(41, 156, 252, 0.1);
    }
    &::after {
      background: #43BCFF;
    }
  }
  &.bg-feature-4 {
    .feature-icon {
      background: rgba(96, 177, 88, 0.15);
    }
    &::after {
      background: #60B158;
    }
  }
  &.bg-feature-5 {
    .feature-icon {
      background: rgba(255, 150, 39, 0.1);
    }
    &::after {
      background: #FF9414;
    }
  }
  &.bg-feature-6 {
    .feature-icon {
      background: rgba(222, 77, 89, 0.1);
    }
    &::after {
      background: #FC6773;
    }
  }
  &.bg-feature-7 {
    .feature-icon {
      background: rgba(255, 132, 62, 0.1);
    }
    &::after {
      background: #FF843E;
    }
  }
  &.bg-feature-8 {
    .feature-icon {
      background: rgba(221, 84, 255, 0.1);
    }
    &::after {
      background: #FF81FF;
    }
  }
}

.benefit-box  {
  position: relative;
  background: rgba(241, 242, 242, 0.5);
  padding: 40px 10px;
  box-shadow: 17px 17px 20px rgba(186, 186, 187, 0.3);
  border-radius: 30px;

  @media (max-width: 991px) {
     padding: 30px 10px;
  }
  @media (max-width: 575px) {
     padding: 20px 10px;
  }
  svg {
    width: 40px;
    height: 40px;
    @media (max-width: 991px) {
      width: 30px;
      height: 30px;
    }
  }
  &::after {
    position: absolute;
    content: '';
    width: 54px;
    height: 8px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0 0;
  }
  h2 {
    @media (max-width: 991px) {
      font-size: 25px;
    }
  }
  h6 {
    font-weight: 400;
    color: #52526C;
    opacity: 0.7;
  }
  &.pink {
    h2 {
      color: #FF3692;
    }
    &::after {
      background: #FF3692;
    }
  }
  &.purple {
    h2 {
      color: #7238FF;
    }
    &::after {
      background: #7238FF;
    }
  }
  &.red {
    h2 {
      color: #FF3556;
    }
    &::after {
      background: #FF3556;
    }
  }
  &.warning {
    h2 {
      color: #FFAA05;
    }
    &::after {
      background: #FFAA05;
    }
  }
}
.rating-title {
  display: flex;
  align-items: center;
  justify-content: center;
  .decore-1 {
    margin-top: -35px;
    animation: animationtop 5s infinite;
  }
}
@keyframes arrowsvg {
  0% {
    transform: scale(0);
    transform-origin: left;
  }

  100% {
    transform: scale(1);
    transform-origin: left;
  }
}
.title-svg {
  animation: arrowsvg 5s infinite;
}
.ratings {
  li {
    i {
      color: #FFAA05;
    }
  }
}
.components-section {
  background: linear-gradient(179deg, rgba(247, 248, 249, 0) 8.83%, #F7F8F9 50%);
  .vector-image {
    left: 30px;
    top: 110px;
    @media (max-width: 1660px) {
      left: 50px;
      top: 80px;
    }
    @media (max-width: 1399px) {
      left: 30px;
    }
    @media (max-width: 1199px) {
      left: -16px;
    }
    img {
      height: 280px;
      @media (max-width: 1660px) {
        height: 240px;
      }
      @media (max-width: 1399px) {
        height: 220px;
      }
      @media (max-width: 1199px) {
        height: 190px;
      }
    }
  }
}
.component_responsive {
  flex-wrap: initial;
}
.component-hover-effect {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #FCFCFD;
  border: 2px solid $white;
  box-shadow: 0px 4px 10px rgba(46, 35, 94, 0.07);
  border-radius: 5px;
  padding: 20px;
  justify-content: center;
   min-height: 78px;
   @media (max-width: 767px) {
    padding: 13px;
    min-height: 68px;
   }
  svg {
    width: 26px;
    height: 26px;
  }
}

.ptb50 {
  padding: 80px 0;
  @media (max-width: 991.98px) {
    padding: 60px 0;
  }
  @media (max-width: 575.98px) {
    padding: 50px 0;
  }
}
.purchase-btn {
  padding: 12px 20px;
  min-width: 200px;
  border: none;
  @media (max-width: 991px) {
    min-width: 160px;
  }
  @media (max-width: 767px) {
    min-width: 140px;
    padding: 10px 17px;
    font-size: 16px;
  }
}

.footer-bg {
  position: relative;
  text-align: center;
  background: url(../images/landing/footer.jpg);
  margin-left: 0;
  color: $white;
  @media (max-width:575px) {
    background-position: center;
  }
}
@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}
footer {
  
  h2 {
    font-size: calc(24px + (70 - 24) * ((100vw - 300px) / (1920 - 300)));
  }
  .landing-title {
     p {
        color: $white;
        opacity: 0.7;
     }
  }
  .license-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: calc(15px + (30 - 15) * ((100vw - 300px) / (1920 - 300)));
    gap: calc(15px + (30 - 15) * ((100vw - 300px) / (1920 - 300)));
    @media (max-width: 991px) {
      gap: 20px;
    }
    @media (max-width: 767px) {
      gap: 15px;
    }
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
    h6 {
      color: $white;
    }
  }
  .feature-content {
    margin-bottom: calc(25px + (60 - 25) * ((100vw - 300px) / (1920 - 300)));
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: calc(25px + (40 - 25) * ((100vw - 300px) / (1920 - 300)));
      @media (max-width: 767px) {
        gap: 15px;
      }
      @media (max-width: 575px) {
        flex-wrap: wrap;
        gap: 10px;
      }
      li {
        h4 {
          max-width: 205px;
          margin: 0 auto;
          @media (max-width: 1199px) {
            max-width: 200px;
          }
          @media (max-width: 991px) {
            max-width: 140px;
            font-size: 18px;
          }
          @media (max-width: 767px) {
            max-width: 116px;
            font-size: 16px;
          }
          @media (max-width: 575px) {
            max-width: 105px;
            font-size: 14px;
          }
        }
        img {
          margin-top: -40px;
          @media (max-width: 991px) {
            width: 220px;
          }
          @media (max-width: 767px) {
            width: 160px;
            margin-top: -25px;
          }
          @media (max-width: 575px) {
            width: 140px;
          }
        }
      }
    }
  }
  .sub-footer {
    padding: 20px 15px;
    border-top: 1px solid rgba($white, 0.1);
    @media (max-width: 575px) {
      padding: 12px 0px;
    }
    h6 {
      font-weight: normal;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      li {
        a {
          color: $white;
          &:hover {
            color: $primary-color;
          }
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}

.page-wrapper {
  .bg-Widget {
    background: linear-gradient(180deg, #F7F8F9 69%, rgba(247, 248, 249, 0) 91.83%);
    &:before {
      top: 220px !important;
      transform: rotate(-40deg);
    }

    &:after {
      top: unset !important;
      bottom: 0 !important;
    }

    .cuba-demo-content {
      p {
        color: rgba($white, 0.7);
      }

      .couting {
        h2 {
          -webkit-text-fill-color: $white !important;
        }
      }
    }
  }
}

.btn-md:hover {
  animation: 0.3s pulse 3;
}

.tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background: var(--theme-deafult);
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-deafult);
  opacity: 0.5;
  transition: all 0.3s ease;

  &:hover {
    transition: all 0.3s ease;
    opacity: 1;
  }

  svg {
    width: 20px;
  }
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.page-wrapper {
  overflow: hidden;

  .section-space {
    padding: 80px 0;
  }

  .customer-support {
    padding-top: 21px !important;
  }

  .cuba-demo-content {
    text-align: center;
    position: relative;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    img {
      margin: 0 auto;
      width: 70px;
      animation: rotated 50s infinite linear;
    }
  }



  .cuba-demo-section {
    position: relative;

    .img-effect {
      -webkit-box-shadow: 0 0 37px rgba(8,21,66,0.15);
      box-shadow: 0 0 37px rgba(8,21,66,0.15);
      border-radius: 15px;
      padding: 20px;
      position: relative;
      .hover-link {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(10deg, rgba($dark-color, 0.8) 0%, transparent 80%);
        border-radius: 15px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 20px;
        opacity: 0;
        transition: all 0.3s ease;
        left: 0;
        top: 0;
       
      }

      &:hover {
        .hover-link {
          opacity: 1;
          z-index: 1;
          transition: all 0.3s ease;
        }
      }

      img {
        width: 100%;
        transition: all 0.3s ease;
      }

      &:hover {
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
        transition: all 0.3s ease;

        img {
          transform: scale(1.01);
          transition: all 0.3s ease;
        }
      }
    }

    .cuba-demo-content {
      .couting {
        margin-bottom: -10px;
        margin-top: calc(-15px + (-32 - -15) * ((100vw - 300px) / (1920 - 300)));
        h2 {
          color: var(--theme-deafult);
          font-weight: 500;
          font-size: calc(100px + (250 - 100) * ((100vw - 300px) / (1920 - 300)));
          background: linear-gradient(180deg, #5456AA 0%, rgba(0, 2, 72, 0) 88.85%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0;
          line-height: 1;
           opacity: 0.1;
        }
      }
    }
  }
}
.fluid-space {
  padding: 0 calc(10px + (80 - 10) * ((100vw - 300px) / (1920 - 300)));
  @media (max-width: 1399px) {
    padding: 0 30px;
  }
  @media (max-width: 575px) {
    padding: 0 12px;
  }
}
ul.dot-group {
  display: flex;
  gap: 6px;
  padding: 15px 10px 0;
  li {
    width: 8px;
    height: 8px;
    background: #E8E8EC;
    border-radius: 100%;
  }
}
.demo-imgs {   
  margin: -15px; 
    .demo-content {
      .cuba-demo-img {
        box-shadow: 0px 9px 40px rgba(46, 35, 94, 0.15);
        border-radius: 5px;
        position: relative;
        height: 100%;
        .hover-link {
          padding: 20px;
          display: -ms-flexbox;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;
          background: $body-color;
          border-radius: 0px 0px 5px 5px;
          @media (max-width: 1640px) {
            padding: 20px 12px;
            gap: 10px;
          }
          @media (max-width: 1366px) {
            padding: 20px;
          }
          .link-btn {
            cursor: not-allowed;
          }
          a {
            color: $white;
            display: inline-block;
            border-radius: 14px;
            background: $body-color;
            transition: all 0.3s ease;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 7px 7px 5px rgba(192, 202, 216, 0.22);
            position: relative;
            @media (max-width: 1640px) {
              width: 55px;
              height: 55px;
            }
            img {
              margin-bottom: -2px;
            }
          }
          .coming-soon {
            opacity: .40;
            pointer-events: none;
          }
        }
        .overflow-hidden { 
          padding: 10px;
          img {
            width: 100%;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        }
        &:hover {
          .overflow-hidden {
            img {
              -webkit-transform: scale(1.01);
              transform: scale(1.01);
            }
          }
        }
      }
      .title-wrapper {
        padding: 0 10px 0;
        .theme-name {
          position: relative;
          font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1920 - 300)));
          text-transform: capitalize;
          span {
            color: #52526C;
          }
        }
      }
    }
    @media (max-width: 1670px) {
      > div {
        padding: 15px;
        margin: 0;
      }
    }
}
.app-box {
  padding: 18px 12px 12px;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 5px;
  h6 {
    color: #52526C;
    margin: 0 12px 0 0;
    position: relative;
    .app-title {
      writing-mode: vertical-rl; 
      transform: rotate(180deg);
    }
    .line {
      width: 1px;
      background: #52526C;
      height: 18px;
      position: absolute;
      top: -30px;
      left: 50%;
    }
  }
  .app-image {
    flex: auto;
    .img-effect {
      box-shadow: 0px 3px 20px rgba(46, 35, 94, 0.1);
      border-radius: 5px;
    }
    a {
      display: block;
      img {
        width: 100%;
      }
    }
  }
  ul.dot-group {
    padding: 0 0 13px;
  }
}
.app-bg-1 {
  background: #F9F7F7;
}
.app-bg-2 {
  background: #F3F5F5;
}
.app-bg-3 {
  background: #EFF5F8;
}
.app-bg-4 {
  background: #F0F5F7;
}
.app-bg-5 {
  background: #F5F4F3;
}
.app-bg-6 {
  background: #F8F8FA;
}
.text-marqee {
  height: 230px;
  @media (max-width: 1199px) {
    height: 166px;
  }
  @media (max-width: 991px) {
    height: 130px;
  }
  @media (max-width: 767px) {
    height: 95px;
  }
  @media (max-width: 575px) {
    height: 65px;
  }
}
.decoration {
  li {
    position: absolute;
  }
}
.flower-gif {
  top: -40px;
  left: 19%;
  @media (max-width: 991px) {
    top: 0;
    left: 15%;
  }
  @media (max-width: 767px) {
    left: 8%;
  }
  @media (max-width: 575px) {
    display: none;
  }
}
.big-title {
  font-weight: 700;
  font-size: 200px;
  line-height: 229px;
  letter-spacing: -0.045em;
  color: rgba(234, 235, 241, 0.5);
  margin-bottom: 0;
  @media (max-width: 1199px) {
    font-size: 140px;
    line-height: 170px;
  }
  @media (max-width: 991px) {
    font-size: 110px;
    line-height: 140px;
  }
  @media (max-width: 767px) {
    font-size: 80px;
    line-height: 100px;
  }
  @media (max-width: 575px) {
    font-size: 50px;
    line-height: 60px;
  }
}

//navbar
.navabr_btn-set {
  position: relative;

  &:focus {
    outline: 0;
  }

  span {
    display: block;
    background-color: $white;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }

  &:not(.custom_nav) {
    span {
      &:nth-child(1) {
        position: absolute;
        left: 12px;
        top: 10px;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        opacity: 0.9;
      }

      &:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }

      &:nth-child(3) {
        position: absolute;
        left: 12px;
        top: 10px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        opacity: 0.9;
      }
    }
  }
}

.navbar-b {
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-top: 1.563rem;
  padding-bottom: 1.563rem;
}

.fixed-top {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  left: 0;
  z-index: 1030;
}

.navbar-toggler {
  span {
    display: block;
    background-color: $transparent-color;
    height: 3px;
    width: 25px;
    z-index: 112;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;

    &:nth-child(1) {
      transition: transform .35s ease-in-out;
    }

    &:nth-child(3) {
      transition: transform .35s ease-in-out;
    }
  }
  &:focus {
    box-shadow: none;
  }
}

.navbar_nav_modify {
  li {
    text-align: center;
  }
}

//sticky header
.sticky-header {
  header {
    position: fixed;
    width: 100%;
    z-index: 6;
    text-align: center;
    line-height: normal;
    height: 80px;
    background: $landing-color;
    color: $white;
    box-shadow: 0 0 5px 1px rgba(5, 56, 111, 0.1);
    transition: all 0.4s ease;

    .nav-padding {
      padding: 14px;
    }

    &.sticky {
      line-height: normal;
      background: $landing-color;
      text-align: left;

      .nav-padding {
        padding: 7px 14px;
      }
    }
  }
}

.landing-home {
  .sticky .navbar-brand img {
    max-height: 35px;
  }
}

.title-padding {
  padding: 130px 0;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-4px);
  }
}

// Landing page responsive css start
@media (max-width: 1440px) {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 16px;
        padding: 12px 7px;
      }
    }
  }
}

/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .sticky-header header.sticky .nav-padding {
    padding: 15px 25px;
  }

  .navbar-toggler {
    span {
      background-color: $white;
    }
  }

  .landing-home {
    .screen1 {
      left: 100px;
      margin-top: 0;
    }
  }

  .sticky-header {
    .navbar-nav {
      .nav-item .nav-link {
        color: $black;
        padding: 10px 12px !important;
        font-size: 15px;
      }

      li {
        display: block;
        width: 100%;
      }
    }
  }

  .landing-page {

    .navbar-expand-xl {
      .navbar-collapse {
        background-color: white;
        margin-top: 20px;
        position: absolute;
        width: calc(100% - 30px);
        left: 15px;
        top: 70px;
        @media (max-width: 575px) {
          top: 40px;
        }
        .navbar-nav {
          padding: 20px 30px 30px 30px;
        }
      }
    }
  }
  header.sticky {
     .navbar-expand-xl {
      .navbar-collapse {
        top: 50px;
      }
     }
  }

  .landing-main {
    .sticky {
      .navbar-expand-xl {
        .navbar-brand {
          top: 8px;
        }

        .navabr_btn-set {
          top: 16px;
        }
      }
    }

    .navbar-toggler {
      span {
        background-color: $white;
      }
    }

    .navbar_nav_modify {
      width: 100vw;
      left: 0;
      position: fixed;
      height: 100vh;
      background-color: #293240;
      top: 0;
      padding: 120px 0px;
    }

    h4 {
      font-size: 20px;
    }

    .navbar-expand-xl {
      .navabr_btn-set {
        position: fixed;
        top: 24px;
        z-index: 1;
        right: 20px;
      }

      .navbar-brand {
        padding-left: 5px;
        z-index: 1;
        position: fixed;
        top: 14px;
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            a {
              padding: 28px 60px;
              width: 16px;
              margin: 0 auto;
              text-align: center;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    .navbar-expand-lg {
      .navbar-brand {
        padding-left: 5px;
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            a {
              padding: 9px;
            }
          }
        }
      }
    }
  }
}

/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .landing-home {

    .navbar-brand img {
      height: 35px;
    }
  }

  .frameworks-section {
    >.container {
      max-width: 100%;
    }
  }
  .landing-main {
    .navbar-expand-lg .navbar-collapse {
      position: absolute;
      top: 90px;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 30px);
      border-radius: 5px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      padding: 15px;
    }
  }

  .landing-main {
    .section-space {
      padding: 60px 0;

      p {
        font-size: 16px;
      }
    }

    .navbar-expand-lg {
      .navbar-toggler {
        background-color: $white;

        .navbar-toggler-icon {
          i {
            margin-top: 5px;
            color: var(--theme-deafult);
          }
        }
      }

      .navbar-collapse {
        background-color: $white;
        z-index: 111;

        .navbar-nav {
          .nav-item {
            a {
              color: $black;
            }
          }
        }
      }
    }

    .footer-bg {
      h2 {
        font-size: 28px;
      }
    }
  }
}

/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {
  .landing-page .sticky-header header.sticky .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    padding: 10px 12px;
  }
}

/* ========= responsive scss for 575 screen ========= */
@media only screen and (max-width: 575px) {
  .landing-home .sticky-header header .nav-padding {
    padding: 15px 30px;
  }

  .page-wrapper {
    .cuba-demo-content {
      margin-bottom: 20px;
    }

    .section-space {
      padding: 40px 0;
    }
  }

  .landing-main {
    h4 {
      font-size: 17px;
    }

    .footer-bg {
      h2 {
        font-size: 21px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {

  .sticky-header header.sticky {
    height: 56px;
  }

  .landing-home {
    .sticky-header header .nav-padding {
      padding: 15px;
    }

    .navbar-toggler {
      border: none;
      background-color: transparent;
      padding: 0;
    }

    .navbar-brand img {
      height: 25px;
    }
  }

}

@media only screen and (max-width: 380px) {
  .component-col-set{
    width: 100%;
  }
}
/**=====================
     38. Landing CSS Ends
==========================**/